//Mallikarjun code
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import {
  Table,
  Button,
  Input,
  Space,
  Typography,
  Divider,
  pagination,
  Badge,
  Avatar,
  Tooltip,
} from 'antd';
import { DownloadOutlined, EyeFilled, SearchOutlined } from '@ant-design/icons';
import {
  getEmployeeList,
  searchEmployee,
  getWorkmenLimit,
} from '../services/auth';
import Highlighter from 'react-highlight-words';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import ExcelJS from 'exceljs';
import { downloadEmployeeList } from '../services/auth';
import { setLoader } from '../actions';
import useAuth from '../hooks/useAuth';

const PermanentEmployeeList = () => {
  const [sortedInfo, setSortedInfo] = useState({});
  const [Employee, setEmployee] = useState([]);
  console.log('Employee', Employee);
  const [loading, setloading] = useState(true);
  const [activityChanged, setActivityChanged] = useState(false);
  // Search icon
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [pageNo, setpageNo] = useState(1);
  const [employementType, setemployementType] = useState('Permanent');
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [shouldResetPagination, setShouldResetPagination] = useState(false);
  const [data, setData] = useState(null);
  const [sortedData, setSortedData] = useState([]);
  const searchInput = useRef(null);
  const { userConfig } = useAuth();

  const contractorId = userConfig?.supplierId;
  const orgId = userConfig?.orgId;
  const role = userConfig?.role;

  useEffect(() => {
    EmployeeList(pageSize, pageNo, employementType);
  }, [pageSize, pageNo, role]);

  // const EmployeeList = async (pageSize, pageNo, employementType) => {
  //   setloading(true);
  //   try {
  //     const res = await getEmployeeList(pageSize, pageNo, employementType);
  //     setTotalCount(res?.data?.count);

  //     const employeeData = res?.data?.passList?.map((row) => structureEmployee(row));

  //     // const filteredData = userConfig.role === 'ir'
  //     //   ? employeeData.filter((employee) => employee.employementType === 'Permanent')
  //     //   : employeeData;
  //     setEmployee(employeeData);

  //     if (shouldResetPagination) {
  //       setpageNo(1);
  //       setShouldResetPagination(false);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     setloading(false);
  //   }
  // };

  const EmployeeList = useCallback(
    async (pageSize, pageNo, employementType) => {
      setloading(true);
      try {
        const res = await getEmployeeList(pageSize, pageNo, employementType);
        setTotalCount(res?.data?.count);
        const employeeData = res?.data?.passList?.map((row) =>
          structureEmployee(row)
        );

        const sortedData = [...employeeData].sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setEmployee(sortedData);
      } catch (error) {
        console.error(error);
      } finally {
        setloading(false);
      }
    },
    []
  );

  const structureEmployee = (row) => {
    return {
      key: row?._id,
      id: row?.id,
      _id: row?._id,
      firstName: `${row.personalDetails?.firstName} ${row.personalDetails?.lastName}`,
      aadharNo: row?.aadhar[0]?.maskedAadhaarNumber || '',
      // aadharNo: row?.personalDetails.aadharNo || '',

      village: row.personalDetails?.village,
      address: row?.personalDetails?.address,
      state: row?.personalDetails?.state,
      district: row?.personalDetails?.district,
      pincode: row?.personalDetails?.pincode,
      mobile: row?.personalDetails?.mobile,
      fatherName: row?.personalDetails?.fatherName,
      gender: row?.personalDetails?.gender,
      Uan: row?.personalDetails?.uan,
      pfAccountNo: row?.personalDetails?.pfAccountNo,
      psNumber: row?.jobDetails?.psNumber,
      esicno: row?.personalDetails?.esicno,
      // empCode: row?.personalDetails?.empCode,
      //Job Details
      departmentCode: row?.jobDetails?.departmentCode,
      sectionCode: row?.jobDetails?.sectionCode,
      designation: row?.jobDetails?.designation,
      bloodGroup: row?.jobDetails?.bloodGroup,
      AccountNo: row?.jobDetails?.AccountNo,
      bankname: row?.jobDetails?.bankname,
      name: row?.jobDetails?.name,
      ifsc: row?.jobDetails?.ifsc,
      employeeType: row?.jobDetails?.employeeType,
      userName: row?.jobDetails?.userName,
      academicQualification: row?.jobDetails?.academicQualification,
      jobCode: row?.jobDetails?.jobCode,
      labourType: row?.jobDetails?.labourType,
      itemServiceCode: row?.jobDetails?.itemServiceCode,
      //Wage Details
      basic: row?.wageDetails?.basic,
      da: row?.wageDetails?.da,
      userName: row?.wageDetails?.userName,
      allowance1: row?.wageDetails?.allowance1,
      allowance2: row?.wageDetails?.allowance2,
      allowance3: row?.wageDetails?.allowance3,
      conveyance: row?.wageDetails?.conveyance,
      hra: row?.wageDetails?.hra,
      ot: row?.wageDetails?.ot,
      status: row?.status
        ? row?.status[0]?.toUpperCase() + row?.status?.slice(1)
        : '',
      createdBy: row?.createdBy,
      EditedBy: row?.EditedBy ? row?.EditedBy : 'NA',
      employementType: row?.employementType,

      locationId: row?.locationId,
      empCode: row?.personalDetails?.empCode,
      approvalStage: row?.approvalStage,

      erdate: dayjs(row?.erdate)?.format('DD-MM-YYYY'), // Format erdate
      medicaldate: dayjs(row?.medicaldate)?.format('DD-MM-YYYY'), // Format medicaldate
      safetydate: dayjs(row?.safetydate)?.format('DD-MM-YYYY'), // Format safetydate
      supervisordate: row?.supervisordate
        ? dayjs(row?.supervisordate)?.format('DD-MM-YYYY')
        : 'NA', // Format supervisordate
    };
  };

  useEffect(() => {
    // Fetch data when the component mounts
    workmenLimit();
  }, []);

  const workmenLimit = async () => {
    //
    getWorkmenLimit(orgId)
      .then((res) => {
        const jobOrderNumbers = [];
        const maxContractorsArray = [];

        res.data.forEach((item) => {
          if (item.addJobDetails && Array.isArray(item.addJobDetails)) {
            item.addJobDetails.forEach((details) => {
              jobOrderNumbers.push(details?.jobordernumber);
            });
          }
          if (item.addJobDetails && Array.isArray(item.addJobDetails)) {
            item.addJobDetails.forEach((details) => {
              maxContractorsArray.push(details?.maxContractors);
            });
          }
          const uniqueJobOrderNumbers = [...new Set(jobOrderNumbers)];
        });
        const uniqueJobOrderNumbers = [...new Set(jobOrderNumbers)];
      })
      .catch((error) => {
        setLoader(false);
      });
  };
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Enter atleast 5 characters`}
          value={selectedKeys[0]}
          onChange={(e) => {
            if (e.target.value.length >= 5) {
              SearchEmployeeData(e.target.value);
            } else if (
              e.target.value.length === 0 ||
              e.target.value.length === 2
            ) {
              EmployeeList(pageSize, pageNo, employementType);
            }
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  // const handleTableChange = (pagination, filters, sorter) => {
  //   setSortedInfo(sorter);
  //   setpageNo(pagination.current);
  //   setPageSize(pagination.pageSize);
  // };
  const handleTableChange = (pagination, filters, sorter) => {
    const { field, order } = sorter;
    const sorted = [...Employee].sort((a, b) => {
      if (field === 'createdAt') {
        return order === 'descend'
          ? new Date(b.createdAt) - new Date(a.createdAt)
          : new Date(a.createdAt) - new Date(b.createdAt);
      }
      // Handle other sorting logic if needed
      return 0;
    });
    setSortedData(sorted);
  };
  // const SearchEmployeeData = async (search) => {
  //   try {
  //     const res = await searchEmployee(pageSize, pageNo, search);
  //     const employeeData = res.data.passList.map((row) => structureEmployee(row));
  //     console.log("resss",res);
  //     const filteredData = userConfig.role === 'ir'
  //       ? employeeData.filter((employee) => employee.employementType === 'Permanent')
  //       : employeeData;
  //       console.log("filteredData",filteredData);

  //     setEmployee(filteredData);
  //     setTotalCount(res?.data?.count);
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     setloading(false);
  //   }
  // };
  // let filteredData;
  // if (userConfig.role === 'ir') {
  //   filteredData = Employee.filter((supplier) => {
  //     return supplier.employementType === 'Permanent';
  //   });
  // } else {
  //   filteredData = Employee;
  // }
  const SearchEmployeeData = useCallback(
    async (search) => {
      setloading(true);
      try {
        const res = await searchEmployee(pageSize, pageNo, search);
        const employeeData = res.data.passList.map((row) =>
          structureEmployee(row)
        );
        const filteredData =
          userConfig.role === 'ir'
            ? employeeData.filter(
                (employee) => employee.employementType === 'Permanent'
              )
            : employeeData;
        setEmployee(filteredData);
        setTotalCount(res?.data?.count);
      } catch (error) {
        console.error(error);
      } finally {
        setloading(false);
      }
    },
    [pageSize, pageNo, userConfig.role]
  );

  const handleFilterChange = (newFilters) => {
    setpageNo(1);
    setShouldResetPagination(true);
    EmployeeList(pageSize, 1, employementType);
  };
  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
    setpageNo(pagination.current);
    EmployeeList(pageSize, pagination.current, employementType);
  };
  // const onChange = (pagination, filters, sorter, extra) => {
  //   setSortedInfo(sorter);
  // };
  // const onChange = (pagination, filters, sorter) => {
  //   setSortedInfo(sorter);
  //   setCurrentPage(pagination.current);
  //   setPageSize(pagination.pageSize);
  //   // Fetch data based on the new page and page size
  //   EmployeeList(pagination.pageSize, pagination.current);
  // };
  const handlePaginationChange = (pageNo, pageSize) => {
    setpageNo(pageNo);
    setPageSize(pageSize);
  };

  // useEffect(() => {
  //   EmployeeList();
  // }, [userConfig.role]);
  const columns = useMemo(
    () => [
      {
        title: 'Name',
        dataIndex: 'firstName',
        key: 'firstName',
        ...getColumnSearchProps('firstName'),

        filterMode: 'tree',
        filterSearch: true,
        sortOrder:
          sortedInfo.columnKey === 'firstName' ? sortedInfo.order : null,
        ellipsis: true,
        fixed: 'top',
      },

      {
        title: 'Aadhaar No',
        dataIndex: 'aadharNo',
        key: 'aadharNo',
        ...getColumnSearchProps('aadharNo'),
        sorter: (a, b) => a.aadharNo.localeCompare(b.aadharNo),
        sortOrder:
          sortedInfo.columnKey === 'aadharNo' ? sortedInfo.order : null,
        ellipsis: true,
        fixed: 'top',
      },
      {
        title: 'PS Number',
        dataIndex: 'psNumber',
        key: 'psNumber',
        ...getColumnSearchProps('psNumber'),

        filterMode: 'tree',
        filterSearch: true,
        sortOrder:
          sortedInfo.columnKey === 'psNumber' ? sortedInfo.order : null,
        ellipsis: true,
        fixed: 'top',
      },

      //Naveen Code

      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        ...getColumnSearchProps('status'),
        render: (status) => {
          let colorClass;
          let displayStatus = status;
          switch (status) {
            case 'Approved':
              colorClass = 'active';

              displayStatus = 'Active';
              break;
            case 'Success':
              colorClass = 'success';
              break;
            case 'Pending':
              colorClass = 'pending';
              break;
            case 'Rejected':
              colorClass = 'reject';
              break;
            default:
              colorClass = '';
          }
          return <span className={colorClass}>{displayStatus}</span>;
        },
        filterMode: 'tree',
        filterSearch: true,
        // width: '17%',
        sortOrder: sortedInfo.columnKey === 'status' ? sortedInfo.order : null,
        ellipsis: true,
        fixed: 'top',
      },

      {
        // title: 'Pending Task',
        title: 'Transaction Status',

        dataIndex: 'status',
        key: 'status',
        render: (stage) => {
          if (stage === 'Pending') {
            return 'Approval Pending With IR';
          } else {
            // return 'Approved & Synched With WFD';
            return 'Approved';
          }
        },
      },
      {
        title: 'Last Action Date',
        dataIndex: 'supervisordate',
        key: 'supervisordate',
      },
      // {
      //   title: 'Last Action Date',
      //   dataIndex: 'approvalStage',
      //   key: 'approvalStage',
      //   render: (stage, record) => {
      //     if (stage === 0) {
      //       return <>{record.supervisordate}</>;
      //     } else if (stage === 1) {
      //       return <>{record.erdate}</>;
      //     } else if (stage === 2) {
      //       return <> {record.medicaldate}</>;
      //     } else {
      //       return <>{record.safetydate}</>;
      //     }
      //   },
      //   fixed: 'top',
      // },
      {
        title: 'Location Id',
        dataIndex: 'locationId',
        key: 'locationId',
        // ...getColumnSearchProps('locationId'),

        filterMode: 'tree',
        filterSearch: true,
        sortOrder:
          sortedInfo.columnKey === 'locationId' ? sortedInfo.order : null,
        ellipsis: true,
        fixed: 'top',
      },
      {
        title: 'Actions',
        dataIndex: '_id',
        render: (id) => (
          <Typography>
            <Link
              to={`/home/ir/employee/${id}`}
              disabled={activityChanged ? true : false}>
              <EyeFilled />
            </Link>
          </Typography>
        ),
        fixed: 'top',
      },
    ],
    [sortedInfo, searchText, searchedColumn]
  );

  //Download Excel Sheet
  const createExcelSheet = (employeeData) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Employee List');

    // Add headers
    worksheet.addRow([
      'Emp Code',
      'First Name',
      'Last Name',
      'Aadhar Number',
      'Mobile Number',
      'Father Name',
      'Address ',
      'Village',
      'District',
      'State',
      'Pincode',
      'Pf Account Number',
      'ESI',
      'UAN',
      'Location Id',
      'Department Code',
      'Contractor Code',
      'Section Code',
      'Academic Qualification',
      'Designation',
      'Job Code',
      'Labour Type',
      'Item Service Code',
      'Blood Group',
      'Bank Name',
      'Account Holder Name',
      'Ifsc Code',
      'Account Number',
      'Basic',
      'DA',
      'Allowance1',
      'Allowance2',
      'Allowance3',
      'Conveyance',
      'HRA',
      'OT',
    ]);

    // Add employee data
    employeeData.forEach((employee) => {
      worksheet.addRow([
        employee.empCode,
        employee.firstName,
        employee.lastName,
        employee.aadharNo,
        employee.mobile,
        employee.fatherName,
        employee.address,
        employee.village,
        employee.district,
        employee.state,
        employee.pincode,
        employee.pfAccountNo,
        employee.esicno,
        employee.uan,
        employee.locationId,
        employee.departmentCode,
        employee.contractorCode,
        employee.sectionCode,
        employee.academicQualification,
        employee.designation,
        employee.jobCode,
        employee.labourType,
        employee.itemServiceCode,
        employee.bloodGroup,
        employee.bankname,
        employee.userName,
        employee.ifsc,
        employee.AccountNo,
        employee.basic,
        employee.da,
        employee.allowance1,
        employee.allowance2,
        employee.allowance3,
        employee.conveyance,
        employee.hra,
        employee.ot,
      ]);
    });

    // Generate a blob containing the Excel data
    return workbook.xlsx.writeBuffer().then((buffer) => {
      return new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
    });
  };

  const downloadExcel = () => {
    const url = `/pass/downloademployeelist`;
    const data = {
      reportType: 'all',
    };
    let responseType, fileExtension, contentType;

    responseType = 'blob';
    fileExtension = 'xlsx';
    contentType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

    downloadEmployeeList(data, responseType)
      .then((response) => {
        const file = new Blob([response.data], { type: contentType });
        const fileUrl = window.URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', `file.${fileExtension}`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // let filteredData = Employee;
  // if (userConfig.role === 'ir') {
  //   filteredData = Employee.filter((employee) => employee.employementType === 'Permanent');
  // }
  // console.log("filteredData",filteredData);

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {/* showZero color="#faad14" size="large" */}
        <h1>
          <b>Workmen List</b>
        </h1>
        <Button type='primary' onClick={downloadExcel}>
          <Tooltip title='Download Employee List'>
            <DownloadOutlined />
          </Tooltip>
        </Button>
      </div>

      <Divider />

      <Space
        direction='vertical'
        style={{ width: '100%' }}
        size='middle'
        align='center'>
        <Table
          columns={columns}
          dataSource={Employee}
          pagination={{
            current: pageNo,
            pageSize,
            total: totalCount,
            // onChange: (page) => setpageNo(page),
            onChange: (page, pageSize) => {
              setpageNo(page);
              setPageSize(pageSize);
              EmployeeList(pageSize, page);
            },
          }}
          onChange={handleTableChange}
          loading={loading}
          rowKey='key'
          // scroll={{ y: 310 }}
        />
      </Space>
    </>
  );
};

export default PermanentEmployeeList;
