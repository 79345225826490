import axios from './axiosInstance';

export const login = (data) => {
  return axios.post('/user/login', data);
};

//User Creation
export const userCreate = (data) => {
  return axios.post('/user/register', data);
};

//Forgot Password
export const forgotPassword = (data) => {
  return axios.post('/forgotpassword', data);
};

export const ssoLogin = () => {
  return axios.post('/sso');
};

//Change Password

export const ChangePassword = (data) => {
  return axios.post('/changepassword', data);
};
//Change Password userid
export const ChangePasswordbyuserid = (data) => {
  return axios.post('/changepass', data);
};
//Logout

export const logout = (data) => {
  return axios.get('/user/logout');
};
//Info

export const info = (data) => {
  return axios.get('/user/info');
};

//Get Method
// export const getEmployeeList = (pageSize, pageNo) => {
//   return axios.get(`/pass?pageSize=${pageSize}&pageNo=${pageNo}&fullData=1`);
// };

//Mallikarjun Code
export const getEmployeeList = (pageSize, pageNo, employementType, set) => {
  return axios.get(
    `/pass?pageSize=${pageSize}&pageNo=${pageNo}&employementType=${employementType}`
  );
};

//For Reviever Role
export const getEmployeeListRR = (pageSize, pageNo, employementType, set) => {
  return axios.get(
    `/passrr?pageSize=${pageSize}&pageNo=${pageNo}&employementType=${'Permanent'}`
  );
};

export const getWorkmenLimit = (orgId) => {
  return axios.post('/passget', { orgId: orgId });
};

export const getPSNumber = (orgId, locationChangedId) => {
  //
  return axios.get('/psnumber', {
    params: { locationId: orgId, cadre: locationChangedId },
  });
};

export const getContractWorkmenEpnNumber = (orgId) => {
  //
  return axios.get('/workmenepnnumber', { params: { orgId: orgId } });
};

export const getContractWorkmenWorkerCodeNumber = (orgId) => {
  //
  return axios.get('/contractworkmenworkercode', { params: { orgId: orgId } });
};

export const getRNNumber = (orgId, locationChangedId) => {
  return axios.get('/rnnumber', {
    params: { locationId: orgId, cadre: locationChangedId },
  });
};

export const searchEmployee = (pageSize, pageNo, search) => {
  return axios.get(
    `/pass/search?pageSize=${pageSize}&pageNo=${pageNo}&fullData=1&search=${search}`
  );
};

//For Reviever Role
export const searchEmployeeRR = (pageSize, pageNo, search) => {
  return axios.get(
    `/passrr/search?pageSize=${pageSize}&pageNo=${pageNo}&fullData=1&search=${search}`
  );
};

export const getContractorList = (data) => {
  return axios.get('/supplier?pageSize=100&pageNo=1');
};

export const getContractorOnboard = (data) => {
  return axios.get('/org/detail');
};

export const getEmployeeOnboard = (data) => {
  return axios.get('/pass/details');
};

export const getWorkmenList = (data) => {
  //
  return axios.get('/pass/detailsList', {
    orgId: data,
  });
};

//Download Employee List
export const downloadEmployeeList = (data, responseType) => {
  return axios.post('/pass/downloademployeelist', data, { responseType });
};

//Download Employee List For Reviever Role
export const downloadEmployeeListRR = (data, responseType) => {
  return axios.post('/pass/downloademployeelistRR', data, { responseType });
};

export const getLocation = (data) => {
  return axios.get('/org/locations');
};
//Approver
export const Approver = (data) => {
  return axios.get('/approver');
};

//supplier
export const AddContractors = (data) => {
  return axios.post('/supplier', data);
};

export const Editcontractor = (data) => {
  return axios.post('/supplieredit', data);
};

export const uploadFileMedical = (data) => {
  return axios.post('/file/upload', data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const uploadFileSafety = (data) => {
  return axios.post('/file/upload', data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const checkUniqueJobOrderNumberApi = (jobOrderNumber) => {
  return axios.get(`validateJobOrderNumber?jobordernumber=${jobOrderNumber}`);
};

export const checkUniqueWCPNApi = (wcpn, supplierId) => {
  return axios.get(`validateWCPN?wcpn=${wcpn}&supplierId=${supplierId}`);
};

export const checkUniqueLLNApi = (lln, supplierId) => {
  return axios.get(`validateLLN?lln=${lln}&supplierId=${supplierId}`);
};

//supervisor
export const getsupervisor = (data) => {
  return axios.get('/supervisorlist', {
    orgId: data,
  });
};

export const EpnRenew = (data) => {
  return axios.post(
    '/epnrenew',
    { data: data },
    { headers: { 'Content-Type': 'application/json' } }
  );
};

//Digital Report

export const getDigitalReport = (date, shift) => {
  return axios.get(`/getDigitalReport?date=${date}&shift=${shift}`);
};

export const updateDigitalReport = (data) => {
  return axios.put('/updateDigitalReport', data, {
    headers: { 'Content-Type': 'application/json' },
  });
};

export const getUniqueShift = (project_no) => {
  return axios.get(`/getShiftByProject/${project_no}`);
};

//Put Method

export const contractorOnboard = (data) => {
  return axios.put('/org', data);
};

//update contractor
export const updatecontractor = (data) => {
  return axios.put('/supplierupdate', data);
};

//create and update contractor integration

export const contractorCreate = (data) => {
  //
  return axios.post(
    '/supplierIntegrationsCreate',
    { data: data },
    { headers: { 'Content-Type': 'application/json' } }
  );
};

export const contractorUpdate = (data) => {
  return axios.post(
    '/supplierIntegrationsUpdate',
    { data: data },
    { headers: { 'Content-Type': 'application/json' } }
  );
};

export const organizationUpdate = (data) => {
  return axios.put('/org', data);
};

//Approver 1
export const Email = (data) => {
  return axios.post('/notification/email', data);
};

export const Approved = (data) => {
  return axios.post('/pass/update', data);
};

export const Approved1 = (data) => {
  return axios.post('/pass/update', data);
};

export const Upload = (data) => {
  return axios.post('/pass/pdf', data, {
    responseType: 'arraybuffer',
  });
};

// Downlaod Master Report
export const DownloadReport = (data) => {
  return axios.post('/pass/reportPdf', data, {
    responseType: 'arraybuffer',
  });
};

//Short Onboarding
export const Shortonboarding = (data) => {
  return axios.post('/shortonboard', data);
};

export const GetShortonboarding = (data) => {
  return axios.get('/shortonboard?pageSize=1&pageNo=1');
};
///pfesi
export const Pfesi = (data) => {
  return axios.post('/pfesi', data);
};
//supplier pf esi
export const supplierPf = (data) => {
  return axios.post('/supplierpf', data);
};
export const supplierEsi = (data) => {
  return axios.post('/supplieresi', data);
};
export const supplierGst = (data) => {
  return axios.post('/supplierGst', data);
};
export const supplierPan = (data) => {
  return axios.post('/supplierPan', data);
};
//Aadhar OTP
export const SendOtp = (data) => {
  return axios.post('/aadhaar/Sendotp', data, {
    headers: {
      'Content-Type': 'application/json',
      'x-karza-key': 'H7WDa8fGIWYyDYgi',
    },
  });
};
export const VerifyOtp = (data) => {
  return axios.post('/aadhaar/file', data, {
    headers: {
      'Content-Type': 'application/json',
      'x-karza-key': 'H7WDa8fGIWYyDYgi',
    },
  });
};

const config = {
  headers: {
    'content-type': 'multipart/form-data',
  },
};
// Compliance/Pf

export const epf = (data) => {
  return axios.post('/report/epf', data, config);
};
export const download = (data) => {
  return axios.post('/report/download/Excel', data);
};

// Compliance/Esi
export const esi = (data) => {
  return axios.post('/report/esi', data, config);
};

//update Pass
export const updatePass = (id, data) => {
  return axios.put(`/pass/updated/${id}`, data);
};

//getJobOrderDetails

export const jobOrderCount = async (jobId, contractorCode) => {
  return axios.get('/pass/jobordercount', {
    params: {
      jobId,
      contractorCode,
    },
  });
};

//update pass with cron
export const replaceWorkmen = (payload) => {
  return axios.post('/pass/replaceWorkmen', payload);
};

// upload Bulk data

export const uploadBulkDataApi = (data) => {
  return axios.post(`/pass/uploadWorkmen`, data);
};

//Aadhaar Validation

export const duplicateaadhaarvalidation = (data) => {
  return axios.post('/pass/aadhaarvalidationexcel', data);
};

//Access Token

export const accesstoken = (data) => {
  return axios.post('/accessToken', data);
};

//post data into wfd

export const postdata = (data) => {
  return axios.post('/postData', data);
};

// reset Password

export const resetPassword = (data) => {
  return axios.post('passwordchange', data);
};

// check aadhar uniqueness api

export const callCheckAadharUniquenessApi = (aadhar) => {
  return axios.get(`pass/checkAadhar?aadhar=${aadhar}`);
};

//role permissions
export const AddPermissions = (data) => {
  return axios.post('/rolepermission', data);
};

export const chechUniqueContractorCodeApi = (contractorCode) => {
  return axios.get(`validateContractorCode?contractorCode=${contractorCode}`);
};
export const chechUniqueUserIdApi = (userId) => {
  return axios.get(`validateUserId?userId=${userId}`);
};

export const getDetails = async (orgId) => {
  return await axios.get('/rolepermission', {
    params: {
      orgId: orgId,
    },
  });
};

export const getDetailsDashboard = async (fetchRole) => {
  // Assume this is an API call using fetch or a similar method

  const data = { fetchRole };
  return await axios.post('/details', data);
};

export const deleteRolePermission = async (selectedRoleType, orgId) => {
  return await axios.delete('/rolepermissions', {
    params: {
      orgId: orgId,
      // selectedRole: role,
      selectedRoleType: selectedRoleType,
    },
  });
};

export const getPermissionList = async (orgId, roleName) => {
  return await axios.get('/permission', {
    params: {
      orgId: orgId,
      // selectedRole: role,
      selectedRoleType: roleName,
    },
  });
};

// license management
export const callCreateLicence = (data) => {
  return axios.post('license', data);
};

// cloud storage
export const getCloudInfo = (req, res) => {
  return axios.get('/cloudfiles');
};

//Customization field
export const createCustomizationField = (data) => {
  return axios.post('/custom-fields', data);
};

export const getCustomizationField = () => {
  return axios.get('/custom-fields');
};

export const updateCustomizationField = (id, data) => {
  return axios.put(`/custom-fields/${id}`, data);
};

//excel to backend
export const sendToBackend = (formattedSheetData) => {
  return axios.post('/excelUploadData', {
    data: formattedSheetData,
  });
};

export const getExcelData = (orgId) => {
  return axios.get('/getExcelData', { orgId: orgId });
};

//Histrorical Role
//long service
export const longService = (data) => {
  return axios.post('/longService', data);
};
export const updateLongservice = (id, data) => {
  return axios.put(`/longService/${id}`, data);
};
export const getLongService = () => {
  return axios.get('/getLongServiceData');
};

//Yearly Points
export const yearlyPoints = (data) => {
  return axios.post('/yearlyPoints', data);
};
export const getyearlyPoints = () => {
  return axios.get('/getyearlyPoints');
};
//Yearly Points
export const desciplinaryAction = (data) => {
  return axios.post('/desciplinaryAction', data);
};
export const getdesciplinaryAction = () => {
  return axios.get('/getdesciplinaryAction');
};
//Yearly Points
export const employeeGrowth = (data) => {
  return axios.post('/employeeGrowth', data);
};
export const getemployeeGrowth = () => {
  return axios.get('/getemployeeGrowth');
};
//Yearly Points
export const yearlyLeaves = (data) => {
  return axios.post('/yearlyLeaves', data);
};
export const getyearlyLeaves = () => {
  return axios.get('/getyearlyLeaves');
};
//Download Employee List
//Long Service
export const downloadHistoricalData = (data, responseType) => {
  return axios.post('downloadHistoricalData', data, { responseType });
};
//YearlyPoints
export const downloadYearlyPoints = (data, responseType) => {
  return axios.post('downloadYearlyPoints', data, { responseType });
};
//DesciplinayAction
export const downloadDesciplinayAction = (data, responseType) => {
  return axios.post('downloadDesciplinayAction', data, { responseType });
};
//EmployeeGrowth
export const downloadEmployeeGrowth = (data, responseType) => {
  return axios.post('downloadEmployeeGrowth', data, { responseType });
};
//YearlyLeaves
export const downloadYearlyLeaves = (data, responseType) => {
  return axios.post('downloadYearlyLeaves', data, { responseType });
};

//Download Template
export const downloadLongServiceTemplate = () => {
  return axios.get('/downloadLongService-excel');
};

//PSN Number
export const psnNumber = (psNumber) => {
  return axios.get('/psnNumber', { params: { psNumber } });
};

//Integration PSN Number
export const psnNumberIntegration = (psNumber) => {
  return axios.get('/psnNumberIntegration', { params: { psNumber } });
};

//Sports Group
export const getSportsGroup = () => {
  return axios.get('/sportGroup');
};

export const updateCronStatus = (record) => {
  return axios.post('/cron/update', { jobName: record.jobName });
};

//Sftp servers

export const sftpImmediate = (date, sftpType) => {
  return axios.post('/sftp/immediate', { date: date, sftpType: sftpType });
};

//Upload Document Sachin Code
//upload document
export const uploadDocument = (formData) => {
  return axios.post(`/document/upload`, formData, config);
};

//download document
export const downloadDocument = (fileName) => {
  return axios.get(`/document/download/${fileName}`, { responseType: 'blob' });
};

//get signed url of document
export const getSignedUrl = (fileName) => {
  return axios.get(`/document/getSignedUrl/${fileName}`);
};

//delete document
export const deleteDocumentFromGcp = (fileName) => {
  return axios.delete(`/document/${fileName}`);
};

// Set temporary gate pass validity
export const setTempGatePassValidity = (data) => {
  return axios.post('/pass/setTempGatePassValidity', data);
};
