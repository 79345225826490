import React, { useState } from 'react';
import { Form, Input, Modal, Select, notification } from 'antd';
import useAuth from '../../hooks/useAuth';
import { debounce } from 'lodash';
import createPass from '../../services/employeeonboard';
import { useParams } from 'react-router-dom';

import axios from '../../services/axiosInstance';
import { psnNumberIntegration, getDetails } from '../../services/auth';
import { updatePass } from '../../services/auth';

const { Option } = Select;

export default function DesignationIrFormModal({
  open,
  onOk,
  onCancel,
  initialValues = {},
  checkIsValidAddition,
  isEdit = false,
  locationsData,
}) {
  const formRef = React.useRef();
  const [users, setUsers] = React.useState({});
  const [uidai, setuidai] = useState({});

  const { userConfig } = useAuth();
  const { id, _id } = useParams();
  console.log('_id', initialValues?._id);
  console.log('id', id);

  const orgId = userConfig?.orgId;

  React.useEffect(() => {
    EmployeeList();
    formRef.current?.setFieldsValue(initialValues);
  }, [open, initialValues]);

  const currentValues = formRef.current?.getFieldsValue(); // Get current form values
  const updatedValues = {
    ...currentValues,
    roleName: currentValues?.roleName?.selectedRoleType, // Set roleName to roleName.selectedRoleType value
  };
  formRef.current?.setFieldsValue(updatedValues);

  async function EmployeeList() {
    const Response = await axios.get('/supplier');
    const Response1 = Response.data.suppliers;
    setUsers({ Response1 });
  }

  const onSubmit = (value) => {
    formRef.current?.submit();
  };
  const debouncedHandleInputChange = debounce(async (value) => {
    psnNumberIntegration(value)
      .then((res) => {
        setuidai({
          doj: res?.data[0]?.dateOfJoininig,
          Dob: res?.data[0]?.dateOfBirth,
          mobile: res?.data[0]?.mobile,
          employeeType: res?.data[0]?.employeeStatus,
          addressLine1: res?.data[0]?.addressDetails?.addressLine1,
          addressLine2: res?.data[0]?.addressDetails?.addressLine2,
          addressLine3: res?.data[0]?.addressDetails?.addressLine3,
          village: res?.data[0]?.addressDetails?.village,
          state: res?.data[0]?.addressDetails?.state,
          district: res?.data[0]?.addressDetails?.district,
          pinCode: res?.data[0]?.addressDetails?.pinCode,
          country: res?.data[0]?.addressDetails?.country,
          taluka: res?.data[0]?.addressDetails?.taluka,
          tehsil: res?.data[0]?.addressDetails?.tehsil,
          lastWorkingDate: res?.data[0]?.lastWorkingDate,
          factoryExtension: res?.data[0]?.factoryExtension,
        });
        const employeeName = res?.data[0]?.employeeName || '';
        const [firstName, ...lastNameParts] = employeeName?.split(' ');
        const lastName = lastNameParts.join(' ');
        form.setFieldValue('firstName', firstName);
        form.setFieldValue('lastName', lastName);
        form.setFieldValue('locationId', res?.data[0]?.employeeLocation);
        form.setFieldValue('emailId', res?.data[0]?.email);
        form.setFieldValue('departmentCode', res?.data[0]?.departmentCode);

        notification.success({ message: 'Fetching Details Succesfully' });
      })
      .catch((err) => {
        // notification.error({ message: err.response.data.message });
      });
  }, 1000); // Adjust the debounce delay as needed (500 milliseconds in this example)

  const onFinish = (value) => {
    const data = {
      personalDetails: {
        LongName: value?.firstName + ' ' + value?.lastName,
        firstName: value?.firstName,
        lastName: value?.lastName,
        locationId: value?.locationId,
        Dob: uidai?.Dob,
        mobile: uidai?.mobile,
        employementType: uidai?.employementType,
        emailId: value?.emailId,
        locationId: value?.locationId,
        paddress1: uidai?.addressLine1,
        paddress2: uidai?.addressLine2,
        paddress3: uidai?.addressLine3,
        pvillage: uidai?.village,
        pstate: uidai?.state,
        pdistrict: uidai?.district,
        ppincode: uidai?.pinCode,
        taluka: uidai?.taluka,
        tehsil: uidai?.tehsil,
      },
      jobDetails: {
        psNumber: value?.psNumber,
        departmentCode: value?.departmentCode,
        doj: uidai?.doj,
        lastDateOfEmployement: uidai?.lastWorkingDate,
      },
      wageDetails: {},

      locationId: userConfig?.locationId,

      // aadhar: {},
      createdBy: userConfig?.createdBy,
      orgId: userConfig?.orgId,
      employementType: 'Permanent',
      approvalsRequired: 3,
      Role: value?.reviewersRole,
      supervisordate: new Date().toISOString(),
      userAccountStatus: value?.userAccountStatus,
      status: 'approved',
      autoApproved: true,
      __v: 0,
    };
    if (isEdit) {
      console.log('id135', id);
      console.log('data135', data);

      updatePass(initialValues?._id, data)
        .then((res) => {
          notification.success({
            message: 'Reviewers Roles updated Successfully',
          });
          window.location.reload();
        })
        .catch((err) => {
          notification.error({ message: err.message });
        });
    } else {
      createPass(data)
        .then((res) => {
          notification.success({
            message: 'Reviewers Roles Assigned Succesfully',
          });
          window.location.reload();
        })
        .catch((err) => {
          notification.error({ message: err.response.data.message });
        });
    }
  };

  const _onCancel = () => {
    formRef.current?.resetFields();
    onCancel();
  };

  const [form] = Form.useForm();

  return (
    <Modal
      title={isEdit ? 'Edit' : 'Add Reviewers Role'}
      open={open}
      onOk={onSubmit}
      onCancel={_onCancel}
      okText={isEdit ? 'Save' : 'Add '}
      autoComplete='off'>
      <Form
        name='Designation'
        ref={formRef}
        form={form}
        layout='vertical'
        initialValues={initialValues}
        onFinish={onFinish}>
        <Form.Item
          label='PS Number'
          name='psNumber'
          // rules={[{ required: true, message: 'Enter PS Number' }]}
        >
          <Input onChange={(e) => debouncedHandleInputChange(e.target.value)} />
        </Form.Item>
        <Form.Item
          label='First Name'
          name='firstName'
          rules={[{ required: true, message: 'Enter First Name' }]}>
          <Input />
        </Form.Item>

        <Form.Item
          label='Last Name'
          name='lastName'
          rules={[{ required: true, message: 'Enter Last Name' }]}>
          <Input />
        </Form.Item>
        <Form.Item
          label='Department Code'
          name='departmentCode'
          // rules={[{ required: true, message: 'Enter Last Name' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Reviewers Role '
          name='reviewersRole'
          // initialValue={initialValues?.roleName}
          rules={[{ required: true, message: 'Select Reviewers Role' }]}>
          <Select>
            <Option value='shopSupervisor'>Shop Supervisor</Option>
            <Option value='shopCoordinator'>Shop Coordinator</Option>
            <Option value='shopInCharge'>Shop In-charge</Option>
            <Option value='shopHead'>Shop Head</Option>
            <Option value='pcHead'>PC Head</Option>
            <Option value='buHead'>BU Head</Option>
            <Option value='locationHead'>Location Head</Option>
            <Option value='indepentCompanyHead'>
              Independent Company (IC) Head
            </Option>

            <Option value='planner'>Planner</Option>
            <Option value='planningLead'>Planning Lead</Option>
            <Option value='planningHead'>Planning Head</Option>
            <Option value='deviceAdmin'>Device Admin</Option>
            <Option value='integrationLead'>Integration Lead</Option>
            <Option value='analyticsBIAnalyst'>Analytics - BI Analyst</Option>
            <Option value='payrollAdmin'>Payroll Admin (IR Admin)</Option>
            <Option value='itAdmin'>IT Admin</Option>
            <Option value='centralIr'>Central IR</Option>
            <Option value='centralSubContractingCell'>
              Central Sub Contracting Cell
            </Option>
            <Option value='ukgAdminstartor'>
              UKG Administrator (Superuser)
            </Option>
          </Select>
        </Form.Item>

        <Form.Item
          label='User Account Status'
          name='userAccountStatus'
          // initialValue={initialValues?.roleName}
          rules={[{ required: true, message: 'Select User Account Status' }]}>
          <Select>
            <Option value='avtive'>Active</Option>
            <Option value='inactive'>Inactive</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label='Location'
          name='locationId'
          rules={[{ required: true, message: 'Enter User ID' }]}>
          <Select disabled={isEdit ? true : false}>
            <Option value={userConfig.locationId}>
              {userConfig.locationId}
            </Option>
          </Select>
        </Form.Item>

        <Form.Item
          label='Email'
          name='emailId'
          rules={[
            { required: true, message: 'Enter Email' },
            { type: 'email', message: 'Enter valid Email' },
          ]}>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}
